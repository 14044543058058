import {Locus} from "@/models/Locus";
import {PropertyLossInformation} from "@/models/PropertyLossInformation";
import {LiabilityLossInformation} from "@/models/LiabilityLossInformation";
import {CargoLossInformation} from "@/models/CargoLossInformation";
import {AccidentInformation} from "@/models/AccidentInformation";
export class LossInformation{
    lossLocationDetails!: string;
    otherDescription!: string;
    lossDescription!: string;
    weatherRelated: boolean = false;
    dueToCatastrophicEvent: boolean;
    catastrophicEvent!: string;
    inLitigation: boolean = false;
    location: Locus = new Locus();

    lossType: string;
    lossTypeCode: string;
    lossCause!: string;
    lossCauseCode!: string;
    detailedLossCause!: string;
    detailedLossCauseCode!: string;
    lossLocationSameAsInsured: boolean = false;
    lossLocationSameAsPrimaryContact: boolean = false;
    wasLocationManuallyEntered: boolean = true;

    accidentInformation: AccidentInformation = null;
    propertyLossInformation: PropertyLossInformation = null;
    liabilityLossInformation: LiabilityLossInformation = null;
    cargoLossInformation: CargoLossInformation = null;
}
