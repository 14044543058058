export class VehicleInformation {
  vin!: string;
  year!: string;
  make!: string;
  model!: string;
  damage!: string;
  plateNumber!: string;
  location!: string;
  ownerSameAsInsured!: boolean;
  ownerNotSameAsInsured!: string;
  vehicleKey!: string;
  areTelematicsAvailable: string;
}
