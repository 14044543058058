import {CaProvinces, CombinedStates, UsStates} from "@/models/typelists/States";
import { Countries } from "@/models/typelists/Countries";
import { Suffixes } from "@/models/typelists/Suffixes";
import { PoliceType } from "@/models/typelists/PoliceType";
import { RelationToInsured } from "@/models/typelists/RelationToInsured";
import { AccidentInformation } from "@/models/AccidentInformation";
import { FnolSubmission } from "@/models/FnolSubmission";
import { PropertyLossInformation } from "@/models/PropertyLossInformation";
import { LiabilityLossInformation } from "@/models/LiabilityLossInformation";
import { CargoLossInformation } from "@/models/CargoLossInformation";
import store from "@/store/store";
import { OtherParty } from "@/models/OtherParty";
import { $enum } from "ts-enum-util";
import { Contact } from "@/models/Contact";
import { Locus } from "@/models/Locus";
import { PsarAddress } from "@/models/PolicyData/PsarAddress";
import { LossInformation } from "@/models/LossInformation";
import router from "@/router/router";

export class UiUtils {
  isNullBlankUndefined(testVal: string) {
    return testVal === null || testVal === "" || typeof testVal === "undefined";
  }

  testForTrim(fieldValue: string): string {
    const field =
      fieldValue != null && fieldValue.length != 0 ? fieldValue.trim() : null;

    if (field != null && field.length === 0) {
      return null;
    } else {
      return field;
    }
  }

  checkIfEmpty(field: string) {
    if (field == "" || field == undefined) {
      return null;
    }
    return field;
  }

  convertBooleanToYesNo(value: Boolean): string {
    switch (value) {
      case true: {
        return "Yes";
      }
      case false: {
        return "No";
      }
      default: {
        return null;
      }
    }
  }

  getPostalCodeMaskForLocation(location: Locus): Array<string> | string {
    if (location.postalCode) {
      if (location.countryCode === "US") return ["#####", "#####-####"];
      if (location.countryCode === "CA") return "A#A #A#";
      return "X".repeat(100);
    }
    return "";
  }

  getPostalCodeVmaskaForLocation(location: Locus): Array<string> | string {
    if (location.postalCode) {
      if (location.countryCode === "US") return "#####-####";
      if (location.countryCode === "CA") return "*#* #*#";
    }
    return "";
  }

  getStateCode(state: string): string {
    return $enum(CombinedStates).getKeyOrDefault(state, null);
  }

  getStateFromCode(stateCode: string): string {
    return $enum(CombinedStates).getValueOrDefault(stateCode, null);
  }

  getCountryCode(country: string): string {
    return $enum(Countries).getKeyOrDefault(country, null);
  }

  getSuffixCode(suffix: string): string {
    return $enum(Suffixes).getKeyOrThrow(suffix);
  }

  getPoliceTypeCode(policeType: string): string {
    return $enum(PoliceType).getKeyOrThrow(policeType);
  }

  getRelationToInsuredCode(relationToInsured: string): string {
    return $enum(RelationToInsured).getKeyOrDefault(relationToInsured, null);
  }

  getRoute(givenRoute: string): string {
    return store.state.siteBrand.sitePath + givenRoute;
  }

  navigateAndSaveSubmission(submission: FnolSubmission, route: string) {
    store.dispatch("saveSubmission", submission).then(() => {
      if (!store.state.ssValidationError) {
        this.navigate(route);
      }
    });
  }

  navigate(route: string) {
    window.scrollTo(0, 0);
    store.state.canNavigate = true;
    router.push({
      path: this.getRoute(route),
    });
  }

  formatCityStateZip(city: string, state: string, zip: string): string {
    return `${city}, ${state} ${zip}`;
  }

  getLossPageRoute(lossType: string): string {
    let route = "";

    switch (lossType) {
      case "Auto": {
        route = "accident-information";
        break;
      }
      case "Property": {
        route = "property-loss-information";
        break;
      }
      case "Liability": {
        route = "liability-loss-information";
        break;
      }
      case "Cargo": {
        route = "cargo-loss-information";
        break;
      }
    }
    return route;
  }

  instantiateLossObject(submission: FnolSubmission) {
    switch (submission.lossInformation.lossType) {
      case "Auto": {
        if (!submission.lossInformation.accidentInformation) {
          this.nullAllLossTypeObjects(submission);
          submission.lossInformation.accidentInformation =
            new AccidentInformation();
          submission.otherParty = new OtherParty();
        }
        break;
      }
      case "Property": {
        if (!submission.lossInformation.propertyLossInformation) {
          this.nullAllLossTypeObjects(submission);
          submission.lossInformation.propertyLossInformation =
            new PropertyLossInformation();
        }
        break;
      }
      case "Liability": {
        if (!submission.lossInformation.liabilityLossInformation) {
          this.nullAllLossTypeObjects(submission);
          submission.lossInformation.liabilityLossInformation =
            new LiabilityLossInformation();
        }
        break;
      }
      case "Cargo": {
        if (!submission.lossInformation.cargoLossInformation) {
          this.nullAllLossTypeObjects(submission);
          submission.lossInformation.cargoLossInformation =
            new CargoLossInformation();
        }
        break;
      }
    }
  }

  nullAllLossTypeObjects(submission: FnolSubmission) {
    submission.lossInformation.accidentInformation = null;
    submission.lossInformation.propertyLossInformation = null;
    submission.lossInformation.liabilityLossInformation = null;
    submission.lossInformation.cargoLossInformation = null;
    submission.otherParty = null;
  }

  clearLossCause(submission: FnolSubmission): boolean {
    this.clearDetailedLossCause(submission);
    submission.lossInformation.lossCause = null;
    submission.lossInformation.lossCauseCode = null;
    return false;
  }

  clearDetailedLossCause(submission: FnolSubmission) {
    submission.lossInformation.detailedLossCause = null;
    submission.lossInformation.detailedLossCauseCode = null;
  }

  checkAllOtherSelection(submission: FnolSubmission): boolean {
    let isOtherDescriptionVisible: boolean;
    if (submission.lossInformation.lossCause == "All Other") {
      isOtherDescriptionVisible = true;
    } else {
      isOtherDescriptionVisible = false;
      submission.lossInformation.otherDescription = null;
    }
    return isOtherDescriptionVisible;
  }

  openPanelContent(id: string) {
    document.getElementById(id).style.display = "flex"; // aria-expanded ??
  }

  addressSameAsToggle(
    locationToReceive: Locus,
    locationToCopyFrom: Locus,
    isSameAs: boolean
  ) {
    if (isSameAs) {
      locationToReceive.addressLine1 = locationToCopyFrom.addressLine1;
      locationToReceive.addressLine2 = locationToCopyFrom.addressLine2;
      locationToReceive.city = locationToCopyFrom.city;
      locationToReceive.state = locationToCopyFrom.state;
      locationToReceive.stateCode = locationToCopyFrom.stateCode;
      locationToReceive.country = locationToCopyFrom.country;
      locationToReceive.countryCode = locationToCopyFrom.countryCode;
      locationToReceive.postalCode = locationToCopyFrom.postalCode;
    } else {
      locationToReceive.addressLine1 = null;
      locationToReceive.addressLine2 = null;
      locationToReceive.city = null;
      locationToReceive.state = null;
      locationToReceive.stateCode = null;
      locationToReceive.postalCode = null;
      locationToReceive.country = "United States";
      locationToReceive.countryCode = "US";
    }
  }

  contactSameAsToggle(
    contactToReceive: Contact,
    contactToCopyFrom: Contact,
    isSameAs: boolean
  ) {
    if (isSameAs) {
      contactToReceive.firstName = contactToCopyFrom.firstName;
      contactToReceive.lastName = contactToCopyFrom.lastName;
      contactToReceive.fullName = contactToCopyFrom.fullName ?? null;
      contactToReceive.suffix = contactToCopyFrom.suffix ?? null;
      contactToReceive.suffixCode = contactToCopyFrom.suffixCode ?? null;
      contactToReceive.primaryPhone = contactToCopyFrom.primaryPhone;
      contactToReceive.primaryPhoneExt =
        contactToCopyFrom.primaryPhoneExt ?? null;
      contactToReceive.primaryEmail = contactToCopyFrom.primaryEmail;
      contactToReceive.location.addressLine1 =
        contactToCopyFrom.location.addressLine1;
      contactToReceive.location.addressLine2 =
        contactToCopyFrom.location.addressLine2;
      contactToReceive.location.city = contactToCopyFrom.location.city;
      contactToReceive.location.state = contactToCopyFrom.location.state;
      contactToReceive.location.stateCode =
        contactToCopyFrom.location.stateCode;
      contactToReceive.location.country = contactToCopyFrom.location.country;
      contactToReceive.location.countryCode =
        contactToCopyFrom.location.countryCode;
      contactToReceive.location.postalCode =
        contactToCopyFrom.location.postalCode;
    } else {
      contactToReceive.firstName = null;
      contactToReceive.lastName = null;
      contactToReceive.fullName = null;
      contactToReceive.suffix = null;
      contactToReceive.suffixCode = null;
      contactToReceive.primaryPhone = null;
      contactToReceive.primaryPhoneExt = null;
      contactToReceive.primaryEmail = null;
      contactToReceive.location.addressLine1 = null;
      contactToReceive.location.addressLine2 = null;
      contactToReceive.location.city = null;
      contactToReceive.location.state = null;
      contactToReceive.location.stateCode = null;
      contactToReceive.location.postalCode = null;
      contactToReceive.location.country = "United States";
      contactToReceive.location.countryCode = "US";
    }
  }

  convertPsarAddressToLocation(
    address: PsarAddress,
    isAddressInTheUS: boolean
  ) {
    const location = new Locus();
    location.addressLine1 = address.streetAddress1;
    location.city = address.city;
    location.postalCode = address.postalCode;
    location.stateCode = address.state;
    location.state = $enum(CombinedStates).getValueOrDefault(location.stateCode, null);
    if (isAddressInTheUS) {
      location.country = "United States";
      location.countryCode = "US";
    }
    return location;
  }

  isInsuredDriverSameAsPrimaryContact(lossInfo: LossInformation) {
    if (
      lossInfo == null ||
      lossInfo.accidentInformation == null ||
      lossInfo.accidentInformation.driver == null
    ) {
      return false;
    }

    return lossInfo.accidentInformation.driver.isSameAsPrimaryContact == true;
  }

  areAnyOtherPartyDriversSameAsPrimaryContact(otherParty: OtherParty) {
    if (
      otherParty == null ||
      otherParty.parties == null ||
      otherParty.parties.length == 0
    ) {
      return false;
    }

    for (const party of otherParty.parties) {
      if (party.driver?.isSameAsPrimaryContact) {
        return true;
      }
    }

    return false;
  }

  resetStateSelectionOnCountryChange(location: Locus) {
    //when selected from a dropdown, the v-model changes the country
    //If the old countryCode does not match the new country, then clear the state
    const oldCountry = $enum(Countries).getValueOrDefault(location.countryCode, null)
    if(oldCountry != location.country) {
      location.state = null;
      location.stateCode = null;
      location.postalCode = null;
    }
  }

  countryOnSelect(location: Locus) {
    location.country = this.testForTrim(location.country);
    this.resetStateSelectionOnCountryChange(location)
    location.countryCode = this.getCountryCode(location.country);
  }

  getStateList(countryCode: string): string[] {
    switch(countryCode) {
      case 'US': return Object.values(UsStates);
      case 'CA': return Object.values(CaProvinces);
      default: return [];
    }
  }

  getCountryList(): string[] {
    return Object.values(Countries);
  }

  //This event is used to correct dropdown usage with Chrome autofill.
  // The deprecated lines ARE needed, DO NOT REMOVE
  triggerEnterKeyPress(id: string) {
    const customEvent = new KeyboardEvent("keydown", {
      altKey: false,
      bubbles: true,
      cancelable: true,
      charCode: 0,
      code: "Enter",
      composed: true,
      ctrlKey: false,
      detail: 0,
      isComposing: false,
      key: "Enter",
      keyCode: 13,
      location: 0,
      metaKey: false,
      repeat: false,
      shiftKey: false,
      which: 13,
    });
    document.getElementById(id).dispatchEvent(customEvent);
  }

  // regen
}


